import styled from 'styled-components';

import { container } from 'theme/sizes';

import { breakpoints } from 'utils/mixins';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin: 0 auto;
  max-width: ${(props) => !props.horizontal && container.default};
  width: ${(props) => (props.horizontal ? '100%' : 'calc(100% - 140px)')};

  .gatsby-image-wrapper {
    width: 100%;
    max-height: 80vh;

    * {
      object-fit: cover;
    }

    picture {
      opacity: 1;
    }
  }

  @media ${breakpoints.bigDesktop} {
    max-width: ${(props) => !props.horizontal && container.default};
  }
`;
