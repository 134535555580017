import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';

import { Wrapper } from './Banner.styles';

const Banner = (props) => {
  //PROPS
  const {
    data: { image },
  } = props;
  //PROPS

  return (
    <Wrapper>
      <GatsbyImage
        image={image?.localFile?.childImageSharp?.gatsbyImageData}
        alt={image?.altText}
      />
    </Wrapper>
  );
};

export default Banner;
