import React from 'react';

import { graphql } from 'gatsby';

import Hero from 'components/hero';
import CTA from 'components/cta';
import Feature from 'components/feature';
import Banner from 'components/banner';

const EquipaTemplate = (props) => {
  //PROPS
  const {
    data: {
      page: {
        hero: { title, text, subtitle },
        team: { bannerTeam, hero },
        cta,
      },
    },
  } = props;
  //PROPS

  return (
    <main className="main">
      <Hero data={{ title, subtitle, text }} center />
      {bannerTeam.map((feature, index) => {
        return <Feature {...feature} key={index} key={index} />;
      })}
      <Banner data={{ image: hero }} />
      {/* <TeamList data={{ team, staff }} /> */}
      <CTA data={cta} />
    </main>
  );
};

export const query = graphql`
  query Equipa($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      hero {
        title
        text
        subtitle
      }
      team {
        infoTitle
        infoBody
        infoSubtitle
        hero {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                aspectRatio: 1.154929577464789
              )
            }
          }
        }
        bannerTeam {
          featureImageFirst
          featureSubtitle
          featureText
          featureTitle
          featureImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  aspectRatio: 1.154929577464789
                )
              }
            }
          }
        }
      }
      cta {
        ctaSubtitle
        ctaTitle
        type
        ctaCta {
          url
          title
          target
        }
        ctaImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

export default EquipaTemplate;
